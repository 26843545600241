<style scoped>
.joborder-prev{
    display: inline-block;
    height: 10px;
    width: 20px;
    background: gray;
}
.joborder-next{
    display: inline-block;
    height: 10px;
    width: 20px;
    background: #4299e1;
}
.joborder-current{
    display: inline-block;
    height: 10px;
    width: 20px;
    border: 1.5px solid #FA8B59;
}
</style>

<template>
    <div>
        <Divider dashed><span class="divider-text">作业令查看</span></Divider>
        <Calendar ref="calendar" is-expanded :is-dark="themeIsDark" :attributes='attributes'
          @dayclick="handleDayClick" @update:from-page="handleFromMonth"/>

        <Row class="p-t-10 text-center text-8">
            <i-col span="8">
               <label class="joborder-prev"/> 已过作业令
            </i-col>
            <i-col span="8">
               <label class="joborder-next"/> 新建作业令
            </i-col>
            <i-col span="8">
               <label class="joborder-current"/> 当前选中
            </i-col>
        </Row>

        <Row v-if="assetList.length>0">
            <i-col span="24">
              <div v-for="(line,index) in assetList" :key="index" class="p-b-10">
                  <h5 class="workplatform-title m-t-10 m-b-10">{{line.name}} :</h5>
                  <Tag color="#404040" v-for="(station,index) in allStationList.filter(x=>x.assetId === line.id)" :key="index">{{station.name}}</Tag>
              </div>
            </i-col>
        </Row>
        <p v-else class="default-content">
          暂无数据
        </p>

    </div>
</template>

<script>
import { Calendar } from 'v-calendar'
import { getStorage } from '@/utils/storage'
import { lastMonthDate, ParseDate } from '@/utils/dateFormat'
import { getWorkdayGatherPage, getWorkdayDetailPage } from '@/api/msp/workday'
// import { getStationList } from '@/api/msp/workday'

export default {
  components: {
    Calendar
  },
  data () {
    return {
      assetList: [], // 所有可用线路
      allStationList: [], // 所有可用站点

      attributes: [],
      chooseDate: ParseDate(new Date()),
      workdayQuery: {
        pageNumber: 1,
        pageSize: 100,
        startDate: '',
        endDate: ''
      },
      prevDates: [],
      nextDates: [],
      workdays: [] // 工作令集合
    }
  },
  computed: {
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  },
  methods: {
    handleFromMonth (param) { // 初始化页面后，会自动加载该事件
      this.workdayQuery.startDate = param.year + '-' + (param.month + '').padStart(2, '0') + '-01'
      this.workdayQuery.endDate = lastMonthDate(param.year, param.month)
      getWorkdayGatherPage(this.workdayQuery).then(res => {
        this.workdays = res.list
        this.initCalendar()
      })
    },
    initCalendar () {
      this.prevDates = []
      this.nextDates = []
      const currentDate = new Date()

      this.workdays.forEach(element => {
        const workday = new Date(element.workday)
        if (workday > currentDate || element.workday === ParseDate(currentDate)) {
          this.nextDates.push(workday)
        } else {
          this.prevDates.push(workday)
        }
      })

      this.handleDayClick({
        id: ParseDate(this.chooseDate),
        date: this.chooseDate,
        isToday: true
      })
    },
    handleDayClick (day) {
      this.initCalendarDates(day)
      this.loadAllStations(day)
    },
    initCalendarDates (day) {
      this.chooseDate = day.id
      this.attributes = [
        {
          key: 'today',
          highlight: { color: 'orange' },
          dates: day.date
        },
        {
          bar: { color: 'gray' },
          dates: this.prevDates
        },
        {
          bar: true,
          dates: this.nextDates
        }
      ]
    },
    loadAllStations (day) {
      getWorkdayDetailPage({ pageNumber: 1, pageSize: 200, workday: day.id }).then(res => {
        if (res && !res.errcode) {
          this.allStationList = res.list.map(x => {
            return {
              id: x.stationId,
              name: x.stationName,
              assetId: x.assetId,
              assetName: x.assetName,
              quantity: x.quantity,
              positionQuantityList: x.positionQuantityList
            }
          })
        } else {
          this.allStationList = []
        }

        this.computedAssetStations()
      })
    },
    computedAssetStations () {
      this.assetList = []
      this.allStationList.forEach(element => {
        if (!this.assetList.find(x => x.id === element.assetId)) {
          this.assetList.push({
            id: element.assetId,
            name: element.assetName,
            count: this.allStationList.filter(x => x.assetId === element.assetId).length // 获取站点数量
          })
        }
      })
    }
  }
}
</script>
