<template>
  <div>
    <!-- <Divider style="color:#fff">作业令查看</Divider> -->
    <workday></workday>
  </div>
</template>

<script>

import { sysMixins } from '@/assets/mixins/sys'
import Workday from '@/components/common/Workday'
export default {
  mixins: [sysMixins],
  components: {
    Workday
  },
  data () {
    return {

    }
  },
  created () {

  },
  computed: {

  },
  methods: {

  }
}
</script>
